import React from 'react';
import Grid, { Row, Column } from '@components/core/Grid';
import useFeaturedArticles from '@hooks/Insights/useFeaturedArticles';
import FeaturedCard from './FeaturedCard';
import InsigthCard from './InsigthCard';
import { makeCustomSlugArticle } from '../../../utils/utils';

import styles from './featured-insights.module.scss';

const FeaturedInsights = () => {
    const { topArticle, featuredArticles } = useFeaturedArticles();

    return (
        <>
            <Grid>
                <Row>
                    <Column>
                        <FeaturedCard {...topArticle} />
                    </Column>
                </Row>
            </Grid>
            <Grid>
                <Column>
                    <div className={styles.specialContainer}>
                        {featuredArticles.map(
                            ({ slug, customSlug, ...rest }) => (
                                <InsigthCard
                                    key={slug}
                                    slug={makeCustomSlugArticle(
                                        slug,
                                        customSlug
                                    )}
                                    {...rest}
                                />
                            )
                        )}
                    </div>
                </Column>
            </Grid>
        </>
    );
};

export default FeaturedInsights;
