import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Img from 'gatsby-image';
import Text from '@components/core/Text';
import Button from '@components/core/Button';
import Eyebrow from '@components/core/Eyebrow';

import styles from './reports-card.module.scss';

const defaultCardBox = {
    minHeight: 504,
    topPostion: 163,
};

export const calculateTopPosition = elementHeight => {
    const topPosition =
        defaultCardBox.topPostion - (elementHeight - defaultCardBox.minHeight);
    if (topPosition > 0) {
        return topPosition;
    }
    return 0;
};

const ReportsCard = ({ data, type }) => {
    const breakpoint = useBreakpoint();
    const cardBoxRef = useRef(null);
    const [cardBoxPositionStyle, setCardBoxPositionStyle] = useState({
        top: defaultCardBox.topPostion,
    });
    const { eyebrow, title, description, coverImage, reportUrl } = data;
    const variantStyle = breakpoint.md ? 'light' : 'dark';

    const reportsCardStyles = `${styles.reportsCard} ${styles[`${type}Card`]}`;
    const cardBoxStyles = `${styles.cardBox} ${styles[`${type}Box`]}`;

    useLayoutEffect(() => {
        const cardBoxHeight =
            (cardBoxRef.current && cardBoxRef.current.clientHeight) || 0;

        if (cardBoxHeight > defaultCardBox.minHeight) {
            setCardBoxPositionStyle({
                top: calculateTopPosition(cardBoxHeight),
            });
        }
    }, [cardBoxRef.current]);

    return (
        <div variant={variantStyle} className={reportsCardStyles}>
            {breakpoint.md && type === 'main' && (
                <div className={styles.imageContainer}>
                    <Img
                        className={styles.coverImage}
                        fluid={coverImage.childImageSharp.fluid}
                        alt={`Reports - ${title}`}
                    />
                </div>
            )}
            <div
                className={cardBoxStyles}
                ref={cardBoxRef}
                style={cardBoxPositionStyle}
            >
                <Eyebrow
                    casing="uppercase"
                    size="paragraphXs"
                    className={styles.eyebrow}
                >
                    {eyebrow}
                </Eyebrow>
                {!breakpoint.md && (
                    <div className={styles.imageContainerMobile}>
                        <Img
                            className={styles.coverImage}
                            fluid={coverImage.childImageSharp.fluid}
                            alt={`Reports - ${title}`}
                        />
                    </div>
                )}
                <Text as="h5" size="h5" className={styles.title} color="white">
                    {title}
                </Text>
                {breakpoint.md && type === 'main' && (
                    <Text
                        as="p"
                        size="paragraphMd"
                        className={styles.description}
                    >
                        {description}
                    </Text>
                )}
                <div className={styles.buttonContainer}>
                    <Button
                        as="a"
                        variant="primary"
                        href={reportUrl}
                        target="_blank"
                    >
                        <Text as="span" size="paragraphXs">
                            Get the book
                        </Text>
                    </Button>
                </div>
            </div>
            {breakpoint.md && type === 'summary' && (
                <div className={styles.imageSummaryContainer}>
                    <Img
                        className={styles.coverImage}
                        fluid={coverImage.childImageSharp.fluid}
                        alt={`Reports - ${title}`}
                    />
                </div>
            )}
        </div>
    );
};

ReportsCard.defaultProps = {
    type: 'main',
};

ReportsCard.propTypes = {
    data: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        coverImage: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
        reportUrl: PropTypes.string,
    }).isRequired,
    type: PropTypes.oneOf(['main', 'summary']),
};

export default ReportsCard;
