/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import useAllArticles from '@hooks/Insights/useAllArticles';
import Button from '@components/core/Button/Button';
import ArticleCard from '@components/Cards/Article';
import Text from '@components/core/Text';
import ArticlesGrid from '@components/ArticlesGrid';
import Section from '@components/core/Section/Section';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import useInfiniteLoad from '@hooks/useInfiniteLoad';
import SubscribeSection from '@sections/Insights/Subscribe';
import PropTypes from 'prop-types';
import styles from './all-articles.module.scss';

const ON_SCREEN_INITIAL_ELEMENTS = 6;
const ON_SCREEN_ELEMENTS_INCREMENT = 6;
const ON_SCREEN_INITIAL_ELEMENTS_MOB = 4;
const ON_SCREEN_ELEMENTS_INCREMENT_MOB = 4;

const AllArticles = ({ data }) => {
    const breakpoints = useBreakpoint();

    const onScreenElementsSize = breakpoints.md
        ? ON_SCREEN_INITIAL_ELEMENTS
        : ON_SCREEN_INITIAL_ELEMENTS_MOB;
    const elementsIncrement = breakpoints.md
        ? ON_SCREEN_ELEMENTS_INCREMENT
        : ON_SCREEN_ELEMENTS_INCREMENT_MOB;

    const articles = useAllArticles();

    const [initialElements, partitionArticles] = useMemo(() => {
        const initialElementsLocal = articles.slice(0, elementsIncrement);
        const partitionArticlesLocal = articles.slice(elementsIncrement);
        return [initialElementsLocal, partitionArticlesLocal];
    }, [onScreenElementsSize, elementsIncrement]);

    const [visibleItems, nextPage, isLastPage] = useInfiniteLoad(
        partitionArticles,
        onScreenElementsSize,
        elementsIncrement
    );

    return (
        <>
            <Section>
                <Grid>
                    <Row>
                        <Column>
                            <Text size="h2" as="h2" className={styles.title}>
                                {data.sectionHeader.title}
                            </Text>
                            <ArticlesGrid articles={initialElements} />
                        </Column>
                    </Row>
                </Grid>
            </Section>
            <Section
                variant={breakpoints.md ? 'light' : 'dark'}
                className={styles.sectionSubscribe}
            >
                <Grid>
                    <Row>
                        <Column>
                            <div>
                                <SubscribeSection
                                    eyebrow={data.subscribe.eyebrow}
                                    title={data.subscribe.title}
                                    content={data.subscribe.summary}
                                />
                            </div>
                        </Column>
                    </Row>
                </Grid>
            </Section>
            <Section>
                <Grid>
                    <Row>
                        <Column>
                            <div className={styles.articlesContainer}>
                                {visibleItems.map(article => {
                                    return (
                                        <ArticleCard
                                            classCardWrapper={
                                                styles.articleItem
                                            }
                                            classImageWrapper={
                                                styles.imageArticle
                                            }
                                            classInfo={styles.info}
                                            key={article.published}
                                            article={article}
                                            sizeTitle="paragraphMd"
                                            layoutDistribution={
                                                breakpoints.md
                                                    ? 'vertical'
                                                    : 'horizontal'
                                            }
                                            showFixedImg={!breakpoints.md}
                                        />
                                    );
                                })}
                            </div>
                            {!isLastPage && (
                                <Button
                                    variant="ghost"
                                    size="block"
                                    onClick={nextPage}
                                >
                                    <Text as="span" size="paragraphSm">
                                        Load more
                                    </Text>
                                </Button>
                            )}
                        </Column>
                    </Row>
                </Grid>
            </Section>
        </>
    );
};

AllArticles.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        subscribe: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            summary: PropTypes.string,
        }),
    }).isRequired,
};

export default AllArticles;
