import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ZoomInImage from '@components/core/ZoomInEffect';
import Img from 'gatsby-image';
import Eyebrow from '@components/core/Eyebrow';
import { Link } from 'gatsby';
import Text from '@components/core/Text';
import { makeCustomSlugArticle } from '../../../utils/utils';

import styles from './featured-insights.module.scss';

const FeaturedCard = ({
    image,
    category,
    slug,
    title,
    featuredText,
    customSlug,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const cleanImage = image && image.childImageSharp.fluid;

    const titleClasses = cx(styles.title, {
        [styles.hover]: isHovered,
    });

    return (
        <Link
            to={makeCustomSlugArticle(slug, customSlug)}
            className={styles.imageContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ZoomInImage isHovered={isHovered}>
                <Img fluid={cleanImage} />
            </ZoomInImage>
            <div className={styles.titleContainer}>
                <Eyebrow casing="uppercase" size="paragraphXs">
                    {category}
                </Eyebrow>

                <div className={styles.mobileLink}>
                    <Text size="h5" as="h5" className={titleClasses}>
                        {title}
                    </Text>
                </div>

                <Text size="paragraphMd" as="p" className={styles.text}>
                    {featuredText}
                </Text>
            </div>
        </Link>
    );
};

FeaturedCard.propTypes = {
    image: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
    category: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featuredText: PropTypes.string.isRequired,
    customSlug: PropTypes.string.isRequired,
};

export default FeaturedCard;
