import React from 'react';
import PropTypes from 'prop-types';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import SubscriptionBox from '@components/core/SubscriptionBox';
import styles from './subscribe.module.scss';

const Subscribe = ({ eyebrow, title, content }) => {
    return (
        <div className={styles.container}>
            <div>
                <Eyebrow className={styles.eyebrow}>{eyebrow}</Eyebrow>
                <Text as="h2" size="h2" className={styles.title} color="white">
                    {title}
                </Text>
                <Text as="h5" size="h5" className={styles.content}>
                    {content}
                </Text>
            </div>
            <div className={styles.rightColumn}>
                <SubscriptionBox variant="primary" sentMessageColor="white" />
            </div>
        </div>
    );
};

Subscribe.propTypes = {
    title: PropTypes.string.isRequired,
    eyebrow: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default Subscribe;
