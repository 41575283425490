import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useReports from '@hooks/useReports';
import Section from '@components/core/Section';
import Grid, { Column, Row } from '@components/core/Grid';
import PreviousNextCarousel from '@components/Carousels/PreviousNextCarousel';
import ReportsCard from '@components/Cards/Reports';
import Text from '@components/core/Text';

import styles from './reports.module.scss';

export const getDektopReportsList = reportsList => {
    if (!Array.isArray(reportsList) || !reportsList.length > 1) {
        return reportsList;
    }

    // The first report is displayed alone, and the following reports are displayed in pairs.
    const reportsListWithoutTheFirstReport = reportsList.slice(1);
    const formatedList = [reportsList[0]];

    return formatedList.concat(chunk(reportsListWithoutTheFirstReport, 2));
};

const SlideElement = (reportsData, index) => {
    if (!Array.isArray(reportsData)) {
        return (
            <div
                className={`${styles.slideItemContainer}`}
                key={`report-card-${index}`}
            >
                <ReportsCard
                    type="main"
                    data={reportsData}
                    key={`reports-${reportsData.title}`}
                />
            </div>
        );
    }
    // It's an array
    return (
        <div className={styles.slideItemContainer} key={`report-card-${index}`}>
            <div className={styles.cardsPairContainer}>
                {reportsData.map(reportData => (
                    <ReportsCard
                        type="summary"
                        data={reportData}
                        key={`reports-${reportData.title}`}
                    />
                ))}
            </div>
        </div>
    );
};

const Reports = ({ data }) => {
    const reportsList = useReports();
    const desktopReportsList = getDektopReportsList(reportsList);
    const breakpoint = useBreakpoint();

    return (
        reportsList &&
        reportsList.length > 0 && (
            <Section className={styles.reportsContainer}>
                <Grid>
                    <Row>
                        <Column className={styles.reportsColumns}>
                            {!breakpoint.md && (
                                <div className={styles.title}>
                                    <Text as="h2" size="h2">
                                        {data.sectionHeader.title}
                                    </Text>
                                </div>
                            )}
                            <div
                                className={styles.reportsSection}
                                data-testid="reports-section"
                            >
                                {!breakpoint.md && (
                                    <PreviousNextCarousel
                                        numberOfItems={reportsList.length}
                                        title={data.sectionHeader.title}
                                        className={styles.slider}
                                    >
                                        {reportsList &&
                                            reportsList.map(reportData =>
                                                SlideElement(reportData)
                                            )}
                                    </PreviousNextCarousel>
                                )}
                                {breakpoint.md && (
                                    <PreviousNextCarousel
                                        numberOfItems={
                                            desktopReportsList.length
                                        }
                                        title={data.sectionHeader.title}
                                        className={styles.slider}
                                    >
                                        {desktopReportsList &&
                                            desktopReportsList.map(
                                                (reportData, index) =>
                                                    SlideElement(
                                                        reportData,
                                                        index
                                                    )
                                            )}
                                    </PreviousNextCarousel>
                                )}
                            </div>
                        </Column>
                    </Row>
                </Grid>
            </Section>
        )
    );
};

Reports.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
};

export default Reports;
