/* eslint-disable no-return-assign */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Text from '@components/core/Text';

import ArrowRightOn from '@components/Icons/slide-arrow-right-on.svg';
import ArrowRightOff from '@components/Icons/slide-arrow-right-off.svg';
import ArrowLeftOn from '@components/Icons/slide-arrow-left-on.svg';
import ArrowLeftOff from '@components/Icons/slide-arrow-left-off.svg';

import styles from './previous-next-carousel.module.scss';

const mobileSettings = {
    arrows: false,
    infinite: true,
    centerMode: true,
    centerPadding: 30,
    speed: 500,
};

const mediumScreensSettings = {
    arrows: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: 0,
};

const PreviousNextCarousel = ({
    children,
    className,
    title,
    numberOfItems,
    config,
}) => {
    const breakpoint = useBreakpoint();

    const maxSlideIndex = numberOfItems - 1;
    const slider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(() => maxSlideIndex === currentSlide);

    let settings = breakpoint.md ? mediumScreensSettings : mobileSettings;
    settings = {
        ...config,
        ...settings,
        afterChange: slideChange => {
            setCurrentSlide(slideChange);
        },
    };

    const classes = cx({ [styles.previousNextCarousel]: true }, className);

    useEffect(() => {
        if (currentSlide === 0) {
            setIsFirst(true);
        } else {
            setIsFirst(false);
        }

        if (maxSlideIndex === currentSlide) {
            setIsLast(true);
        } else {
            setIsLast(false);
        }
    }, [currentSlide]);

    const previous = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentState => currentState - 1);
            slider.current.slickPrev();
        }
    };

    const next = () => {
        if (currentSlide < maxSlideIndex) {
            setCurrentSlide(currentState => currentState + 1);
            slider.current.slickNext();
        }
    };

    return (
        <div className={classes}>
            {breakpoint.md && (
                <div className={styles.headings}>
                    <div className={styles.title}>
                        <Text as="h2" size="h2">
                            {title}
                        </Text>
                    </div>
                    <div className={styles.controlsContainer}>
                        <button
                            type="button"
                            className={styles.control}
                            onClick={previous}
                        >
                            {isFirst ? <ArrowLeftOff /> : <ArrowLeftOn />}
                        </button>
                        <button
                            type="button"
                            className={styles.control}
                            onClick={next}
                        >
                            {isLast ? <ArrowRightOff /> : <ArrowRightOn />}
                        </button>
                    </div>
                </div>
            )}
            <Slider className={styles.carousel} ref={slider} {...settings}>
                {children}
            </Slider>
        </div>
    );
};

PreviousNextCarousel.propTypes = {
    children: PropTypes.node.isRequired,
    numberOfItems: PropTypes.number.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    config: PropTypes.shape({
        dots: PropTypes.bool,
        infinite: PropTypes.bool,
        slidesToShow: PropTypes.number,
        slidesToScroll: PropTypes.number,
        autoplay: PropTypes.bool,
        speed: PropTypes.number,
        autoplaySpeed: PropTypes.number,
        cssEase: PropTypes.string,
    }),
};

PreviousNextCarousel.defaultProps = {
    title: '',
    className: '',
    config: {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: false,
        autoplaySpeed: 0,
        cssEase: 'linear',
    },
};

export default PreviousNextCarousel;
