import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ZoomInImage from '@components/core/ZoomInEffect';
import Img from 'gatsby-image';
import Eyebrow from '@components/core/Eyebrow';
import { Link } from 'gatsby';
import Text from '@components/core/Text';

import styles from './featured-insights.module.scss';

const InsigthCard = ({ image, category, slug, title }) => {
    const [isHovered, setIsHovered] = useState(false);
    const cleanImage = image && image.childImageSharp.fluid;

    const titleClasses = cx(styles.title, {
        [styles.hover]: isHovered,
    });

    return (
        <Link
            to={slug}
            key={title}
            className={styles.articleContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ZoomInImage isHovered={isHovered}>
                <Img fluid={cleanImage} className={styles.image} />
            </ZoomInImage>
            <Eyebrow
                casing="uppercase"
                size="paragraphXs"
                className={styles.eyeBrowCategory}
            >
                {category}
            </Eyebrow>
            <div className={styles.mobileLink}>
                <Text size="h5" as="h5" className={titleClasses}>
                    {title}
                </Text>
            </div>
        </Link>
    );
};

InsigthCard.propTypes = {
    image: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
    category: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default InsigthCard;
