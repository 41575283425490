import React from 'react';
import Layout from '@components/Layout';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import SEO from '@components/SEO';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AllArticles from '@sections/Insights/AllArticles';
import Reports from '@sections/Insights/Reports';
import FeaturedInsights from '@sections/Insights/FeaturedInsights';

const InsightPage = ({
    data: {
        markdownRemark: {
            frontmatter: { title, seo, pageHeader, reports, allArticles },
        },
    },
}) => {
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section>
                <PageHeader infoHeader={pageHeader}>
                    <FeaturedInsights />
                </PageHeader>
            </Section>
            {reports && <Reports data={reports} />}
            <AllArticles data={allArticles} />
        </Layout>
    );
};

InsightPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query InsightsPage {
        markdownRemark(fields: { slug: { eq: "/pages/insights/" } }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                featuredArticles {
                    sectionHeader {
                        eyebrow
                        title
                    }
                }
                reports {
                    sectionHeader {
                        eyebrow
                        title
                    }
                }
                allArticles {
                    sectionHeader {
                        eyebrow
                        title
                    }
                    subscribe {
                        eyebrow
                        title
                        summary
                    }
                }
            }
        }
    }
`;

export default InsightPage;
