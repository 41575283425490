import { useStaticQuery, graphql } from 'gatsby';
import { formatArrayData } from '../../utils/utils';

export default () => {
    const data = useStaticQuery(graphql`
        query GetFeaturedArticles {
            featuredArticles: allMarkdownRemark(
                limit: 2
                filter: {
                    fields: { slug: { glob: "/insights/blog/*" } }
                    frontmatter: {
                        featured: { eq: true }
                        archive: { eq: false }
                    }
                }
                sort: { fields: [frontmatter___published], order: DESC }
            ) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        customSlug: slug
                        published
                        category
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        path
                    }
                }
            }
            topArticle: allMarkdownRemark(
                limit: 1
                filter: {
                    fields: { slug: { glob: "/insights/blog/*" } }
                    frontmatter: {
                        topFeatured: { eq: true }
                        archive: { eq: false }
                    }
                }
                sort: { fields: [frontmatter___published], order: DESC }
            ) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        customSlug: slug
                        published
                        featuredText
                        category
                        featured
                        topFeatured
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        path
                    }
                }
            }
        }
    `);

    return data
        ? {
              topArticle: formatArrayData(data.topArticle.nodes)[0],
              featuredArticles: formatArrayData(data.featuredArticles.nodes),
          }
        : {};
};
